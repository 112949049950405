var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"my-modal",attrs:{"id":"modal-usuario","title":_vm.titulo,"no-close-on-backdrop":"","ok-title":"Guardar","cancel-variant":"outline-secondary","cancel-title":"Cerrar"},on:{"cancel":_vm.cerrarModal,"close":_vm.cerrarModal,"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"Nombre Completo"}},[_c('label',[_vm._v("Nombre Completo "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Nombre Completo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.name),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"Correo Electronico"}},[_c('label',[_vm._v("Correo Electronico "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Correo Electronico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.email),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"celular"}},[_c('label',[_vm._v("Celular "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"celular","state":errors.length > 0 ? false : null,"placeholder":"Numero de celular con WhatsApp"},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.celular),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),(_vm.titulo=='Nuevo Usuario')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"contraseña"}},[_c('label',[_vm._v("Contraseña "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contraseña","state":errors.length > 0 ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.password),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,550630543)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Rol","label-for":"Rol"}},[_c('b-form-select',{attrs:{"options":_vm.roles},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }