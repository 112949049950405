<template>
  <b-modal
    id="modal-usuario"
    ref="my-modal"
    :title="titulo"
    no-close-on-backdrop
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- nombre -->
          <b-col cols="12">
            <b-form-group label-for="Nombre Completo">
              <label >Nombre Completo <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Nombre Completo"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.name"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label-for="Correo Electronico"
            >
              <label >Correo Electronico <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Correo Electronico"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.email"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="celular">
              <label >Celular <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required"
              >
                <b-form-input
                  id="celular"
                  v-model="celular"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Numero de celular con WhatsApp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.celular"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="titulo=='Nuevo Usuario'">
            <b-form-group label-for="contraseña">
              <label >Contraseña <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Contraseña"
                rules="required"
              >
                <b-form-input
                  id="contraseña"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.password"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- contraseña -->
          <!--<b-col cols="12" v-if="titulo=='Nuevo Usuario'">
                    <b-form-group
                        label="Contraseña"
                        label-for="Contraseña"
                    >
                        <validation-provider
                        #default="{ errors }"
                        name="Contraseña"
                        rules="required"
                        >
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            >
                            <b-form-input
                                id="login-password"
                                v-model="password"
                                :state="errors.length > 0 ? false:null"
                                class="form-control-merge"
                                :type="passwordFieldType"
                                name="login-password"
                                placeholder="············"
                            />
                                <b-input-group-append is-text>
                                    <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.password" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>-->

          <b-col cols="12">
            <b-form-group label="Rol" label-for="Rol">
              <b-form-select v-model="rol" :options="roles" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";
import store from "@/store/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, password } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
  },
  props: ["accion", "item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    accion: function () {
      this.errores = [];
      if (this.accion) {
        this.abrir(this.accion, this.item);
        this.getRoles();
      }
    },
    name: function () {
      this.errores.name = [];
    },
    email: function () {
      this.errores.email = [];
    },
    password: function () {
      this.errores.password = [];
    },
        celular: function () {
      this.errores.celular = [];
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      id: null,
      name: "",
      email: "",
      password: "",
      titulo: "",
      celular:"",
      required,
      submitted: false,
      errores: [],
      roles: [],
      rol: "",

    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.titulo == "Nuevo Usuario") {
              this.registrar();
            } else {
              this.actualizar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.passwordFieldType = "password";
      switch (accion) {
        case "registrar": {
          this.titulo = "Nuevo Usuario";
          this.id = "";
          this.name = "";
          this.email = "";
          this.password = "";
          this.celular="";
          this.rol = "";
          break;
        }
        case "actualizar": {
          this.titulo = "Editar Usuario";
          this.id = data["id"];
          this.name = data["name"];
          this.email = data["email"];
          this.celular= data["celular"];
          this.password = "";
          if (data["roles"][0]) {
            this.rol = data["roles"][0].pivot.role_id;
          } else {
            this.rol = "";
          }
          break;
        }
      }
    },
    cerrarModal() {
      this.$emit("cerrarComponente");
    },
    actualizarLista(item) {
      this.$emit("actualizarLista", item);
      this.submitted = false;
    },
    registrar() {
      axiosIns
        .post("/admin/usuarios", {
          name: this.name,
          email: this.email,
          password: this.password,
          celular: this.celular,
          roles: this.rol,
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.actualizarLista(res.data);
          this.errores = [];
        })
        .catch((err) => {
          this.$bvToast.toast('Algo salio mal vuelva a intentar.', {
              title: 'Error',
              variant:'danger',
              solid: false,
          });
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    actualizar() {
      axiosIns
        .put("/admin/usuarios/" + this.id, {
          name: this.name,
          email: this.email,
          celular: this.celular,
          roles: this.rol,
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          store
            .dispatch("auth/attempt", localStorage.getItem("token"))
            .then(() => {});
          this.actualizarLista();
          this.errores = [];
        })
        .catch((err) => {
          this.$bvToast.toast('Algo salio mal vuelva a intentar.', {
              title: 'Error',
              variant:'danger',
              solid: false,
          });
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    getRoles() {
      var url = "/admin/roles";
      axiosIns
        .get(url)
        .then((res) => {
          this.roles = res.data.map((g) => ({ text: g.name, value: g.id }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>